<template>
      <div class="px-3">
        <div class="row mb-3">
          <div class="col d-md-flex">
            <div class="d-md-flex flex-grow-1">
              <div class="mr-md-1 my-1">
                <el-date-picker v-model="filters.month" type="month" class="fw" placeholder="选择月份"></el-date-picker>
              </div>
              <div class="mx-md-1 my-1">
                <el-button type="success" @click="fetchData" :loading="loading" class="fw" :disabled="editing">{{ this.loading ? "加载中..." : "加载" }}</el-button>
              </div>
            </div>
            <div>
              <div class="my-1">
                <el-button type="default" plain v-show="editable && !editing" @click="edit">编辑</el-button>
                <el-button type="default" plain v-show="editing" @click="save">保存</el-button>
                <el-button type="default" plain v-show="editing" @click="cancel">取消</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <el-table refs="l2" row-key="id" header-row-class-name="thead-light" style="width: 100%" v-loading="loading"
              :data="tableData" stripe>
              <el-table-column prop="old.location" label="地区" class-name="pl-2"></el-table-column>
              <el-table-column prop="old.quantity" label="销售数量(吨)">
                <template slot-scope="{row}">
                  <span v-show="!editing">{{ row.old.quantity }}</span>
                  <el-input size="mini" type="number" v-show="editing" v-model="row.new.quantity"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="old.amount" label="销售金额(万元)">
                <template slot-scope="{row}">
                  <span v-show="!editing">{{ row.old.amount }}</span>
                  <el-input size="mini" type="number" v-show="editing" v-model="row.new.amount"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="old.fupin_amount" label="扶贫销售金额(万元)">
                <template slot-scope="{row}">
                  <span v-show="!editing">{{ row.old.fupin_amount }}</span>
                  <el-input size="mini" type="number" v-show="editing" v-model="row.new.fupin_amount"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="old.fupin_population" label="带动贫困人口">
                <template slot-scope="{row}">
                  <span v-show="!editing">{{ row.old.fupin_population }}</span>
                  <el-input size="mini" type="number" v-show="editing" v-model="row.new.fupin_population"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
</template>
<script>
/* eslint-disable */ 
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

export default {
  components: {
    RouteBreadCrumb
  },
  props: {
    storeid: {
        type: Number,
        description: "current object id",
        default: null
    },
    editable: {
        type: Boolean,
        description: "Can update data",
        default: false
    }
  },
  data() {
    return {
      loading: false,
      editing: false,
      filters: { month: new Date() },
      tableData: []
    }
  },
  watch: {
    newYear(v) {
      this.filters.year = v;
      this.fetchData();
    }
  },
  methods: {
    fetchData(isexport) {
      this.loading = true;
      this.axios.get("onlinestorereports/"+this.storeid, { params: { ...this.filters }})
      .then((res) => {
        if (res.data.errcode == 0) {
          this.tableData = res.data.data.map((x)=>{
            return { old: { ...x }, new: { ...x } }
          });
        }
        else {
          this.$notifyErr('未能成功加载数据', res.data.errmsg);
        }
        this.loading = false;
      })
      .catch((e) => {
        console.error(e);
        this.$notifyErr('未能成功加载数据', e.message);
        this.loading = false;
      })
    },
    edit() {
      this.editing = true;
    },
    cancel() {
      this.tableData = this.tableData.map((x)=>{
        return { old: x.old, new: x.old }
      });
      this.editing = false;
    },
    save() {
      var $this = this;
      this.loading = true;
      var newItems = this.tableData.map((x)=>{
        return x.new;
      });
      this.axios.put("onlinestorereports/"+this.storeid, { reports: newItems } )
      .then((res) => {
        if (res.data.errcode == 0) {
            $this.fetchData()
            $this.$notifyOK('操作成功', `已更新该店的销售数据表`);
            $this.loading = false;
            $this.editing = false;
        }
        else {
            $this.$notifyErr('执行保存失败', res.data.errmsg);
        }
      })
      .catch((e) => {
        $this.$notifyErr('执行保存失败', e.message);
        $this.loading = false;
      })
    }
  },
  created() {
    setTimeout(this.fetchData, 500)
  },
};
</script>
<style>
.no-border-card .card-footer { border-top: 0; }
.filters>div { margin-bottom:15px; }
.filters .search.el-input { width: 230px; }
.filters .el-select { width: 160px }
.card .table td, .card .table th, .card .el-table td, .card .el-table th {
    padding: 0.5rem;
}
.align-center .cell {
    text-align: center;
    display: block !important;
    padding: 0;
    line-height: 40px;
}
.filters .fw, .fw.el-input, .fw.el-select, .el-button.fw {
  width: 100% !important;
}
</style>
